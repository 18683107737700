import RegisterPage3 from "../../component/trustee/trustee";
import Loginbg from "../../component/login/loginbg/welcome";

const Trustee = () => {
    return (
        <>
            <RegisterPage3 />
            <Loginbg />
        </>
    );
};

export default Trustee;
